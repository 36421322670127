import { useState } from 'react';
import axios from 'axios';

export default function useAddSubscriber() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const addSubscriber = async (data) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post('/api/addSubscriber',  data, {
        headers: {
          'Dev-Key' : 'iPJGBOiBJUTxCLQ9de88XhMz0uu8H69s'
        }
      });
      setIsLoading(false);
      return response.data; 
    } catch (err) {
      setIsLoading(false);
      setError(err.response.data.error);
      return err; 
    }
  }

  return {
    addSubscriber,
    isLoading,
    error,
  }
}
