import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let scrollTimeout;

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (isScrolling) {
        clearTimeout(scrollTimeout);
      }

      setIsScrolling(true);

      scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    };

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      clearTimeout(scrollTimeout);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const buttonClass = `fixed bottom-2 right-2 z-50 p-2 ${isScrolling ? 'bg-white opacity-30' : 'bg-white opacity-100'} text-maroon border border-maroon-500 rounded cursor-pointer flex items-center gap-2 font-bold`;

  const shouldRenderButton = screenWidth <= 1024;

  return (
    isVisible && shouldRenderButton && (
      <button onClick={scrollToTop} className={buttonClass} aria-label="Scroll to top">
        Back to Top
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-arrow-up"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 12a.5.5 0 0 1-.5-.5V3.707l-2.146 2.147a.5.5 0 0 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 3.707V11.5a.5.5 0 0 1-.5.5z"
          />
        </svg>
      </button>
    )
  );
};

export default ScrollToTopButton;