import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

export default function useSearch() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [text, setText] = useState('');

  const searchGuns = async (query) => {
    setError(null);

    try {
      const response = await axios.get("/api/guns/search", {
        params: { q: query },
        headers: {
          "Dev-Key": "iPJGBOiBJUTxCLQ9de88XhMz0uu8H69s",
        },
      });
      setData(response.data);
    } catch (err) {
      setError(err.response ? err.response.data : "An error occurred");
    }
  };

  useEffect(() => {
    const request = axios.CancelToken.source();

    if (text.length === 0) return setData([]);

    const options = {
      method: "GET",
      url: "/api/guns/search",
      params: { q: text },
      cancelToken: request.token,
      headers: {
        "Dev-Key": "iPJGBOiBJUTxCLQ9de88XhMz0uu8H69s",
      },
    };

    // setIsLoading(true);
    axios
      .request(options)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        setError(err);
      })
      .finally(() => {
        // setIsLoading(false);
      });

    return () => request.cancel();
  }, [text]);

  return {
    data,
    searchGuns,
    error,
    setData,
    text,
    setText
  };
}
