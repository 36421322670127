import useSearch from "@/Hooks/useSearch";
import { Link, router } from "@inertiajs/react";
import { CiSearch } from "react-icons/ci";
import route from 'ziggy-js';
import { useState, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';

export function SearchBar() {
    const { data, searchGuns, setData, text, setText, error } = useSearch();
    // const [text, setText] = useState('');

    const handleInputChange = async (e) => {
        const searchText = e.target.value;
        console.log("search text", searchText)
        setText(searchText);

        setShowSuggestions(true);

        // if (searchText.length === 0) { 
        //    setData([]);
        // } else {
        //     await searchGuns(searchText);
        // }
    }

    const opSearch = useRef(null);

    function showSearch(e) {
        opSearch.current.toggle(e);
    }


    const [showSuggestions, setShowSuggestions] = useState(false);



    const closeSuggestionsWithDelay = () => {
        setTimeout(() => {
            setShowSuggestions(false);
        }, 200);
    };


    const suggestionMouseDown = (e) => {
        e.preventDefault();
    };


    const handleInputBlur = () => {
        closeSuggestionsWithDelay();
    };


    return (
        <>
            <div className="hidden lg:block">
                <form
                    // action={route('search')} 
                    method="GET"
                    className=""
                    onSubmit={(e) => {
                        e.preventDefault();
                        router.visit(route('search', { q: text }))
                        // router.push(route('search', { q: e.target.value }))
                    }}
                >
                    <div className="relative me-2 sm:me-5 flex items-center w-full xl:w-[400px] max-w-[250px]">
                        <span className="absolute left-3">
                            <CiSearch className="w-[20px] h-[20px]" onClick={() => window.document.getElementById("top-search").focus()} />
                        </span>
                        <input
                            type="text"
                            id="top-search"
                            className="border border-[#FFF] sm:border-[#E5E7EB] max-w-[24px] sm:max-w-full focus:max-w-full rounded-lg focus:bg-neutral-100 text-[15px] font-medium ps-11 w-full sm:ps-11 py-[14px]"
                            name="q"
                            value={text}
                            placeholder="Search"
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                        />

                        {data.data?.length > 0 && (
                            <div style={{ zIndex: 1000 }} className="absolute flex flex-col top-[70px] left-0 bg-white w-full rounded-xl overflow-hidden border border-[#E5E7EB] p-2 z-10">
                                {data.data.slice(0, 10)?.map((item) => {
                                    return <Link href={route('item', { gunbrokerId: item.gunbroker_item_id })} className="px-3 py-2 block hover:bg-[#f3f4f6] rounded-lg font-medium text-[15px] text-[#000] ellipsis-1" key={item.id}><span className="flex overflow-hidden text-ellipsis ellipsis-1">{item.title_for_gunbroker}</span></Link>
                                })}
                            </div>
                        )}
                    </div>
                </form>
            </div>


            <div className="block lg:hidden">
                <button
                    onClick={(e) => showSearch(e)}
                    type="button"
                    label="Show"
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21 21.5L16.7 17.2" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>

                <OverlayPanel ref={opSearch} closeOnEscape className="absolute !left-0 top-0 right-0">
                    <form
                        method="GET"
                        className=""
                        onSubmit={(e) => {
                            e.preventDefault();
                            router.visit(route('search', { q: text }))
                        }}
                    >
                        <div className="relative me-2 sm:me-12 flex items-center w-full">
                            <span className="absolute left-3">
                                <CiSearch className="w-[20px] h-[20px]" />
                            </span>
                            <input
                                type="text"
                                id="top-search"
                                className="border border-[#E5E7EB] rounded-lg bg-neutral-100 text-[15px] font-medium w-full px-11 py-[14px] h-[46px]"
                                name="q"
                                value={text}
                                placeholder="Search"
                                onChange={handleInputChange}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setText('')
                                        setData([])
                                    }, 100)
                                }}
                            />

                            {data.data?.length > 0 && (
                                <div className="absolute flex flex-col top-[70px] left-0 bg-white w-full rounded-xl overflow-hidden border border-[#E5E7EB] p-2 z-10">
                                    {data.data.slice(0, 10)?.map((item) => {
                                        return <Link href={route('item', { gunbrokerId: item.gunbroker_item_id })} className="px-3 py-2 block hover:bg-[#f3f4f6] rounded-lg font-medium text-[15px] text-[#000] ellipsis-1" key={item.id}><span className="flex overflow-hidden text-ellipsis ellipsis-1">{item.title_for_gunbroker}</span></Link>
                                    })}
                                </div>
                            )}
                        </div>
                    </form>
                </OverlayPanel>
            </div>
        </>
    );
}
