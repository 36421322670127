export function Button({children, className = "", variant = "maroon", ...props}) {
    let btnClasses = "h-[54px] px-[14.5px] text-neutral-50 rounded-lg transition-colors"

    if (variant === "maroon") {
       btnClasses += " bg-maroon hover:bg-maroon-light"
    }

    if (variant === "green") {
        btnClasses += " bg-[#34A853]"
     }

    return <button {...props} className={btnClasses + " " + className}>{children}</button>
}