import { useForm } from "@inertiajs/react";
import { Button } from "./Button";
import Swal from "sweetalert2";
import useAddSubscriber from "@/Hooks/useAddSubscriber";

export function Join({ className = "" }) {
  const { data, setData, reset } = useForm({
    email: "",
  });

  const { addSubscriber, isLoading, error } = useAddSubscriber();

  // Function to validate email format
  const validateEmail = (email) => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the email is valid
    if (!validateEmail(data.email)) {
      // If email is not valid, show error alert
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "Please enter a valid email address.",
        showConfirmButton: true,
        confirmButtonText: "Try Again",
      });
      return; // Stop the function from proceeding further
    }

    // If email is valid, proceed with adding the subscriber
    const response = await addSubscriber(data);

    try {
      if (response.response.data.error) {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: response.response.data.error,
          showConfirmButton: true,
          confirmButtonText: "Try Again",
          timer: 2000,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "success",
        title: "Success!",
        showConfirmButton: false,
        timer: 2000,
      });

      reset(); 
    }
  };

  return (
    <div className="flex">
      <form action="" onSubmit={handleSubmit}>
        <div className="flex flex-col sm:block gap-y-2">
          <input
            type="email" // Changing type to email for semantic correctness
            className="flex-grow h-[45px] sm:h-[54px] text-black py-[14px] px-4 font-medium w-[290px] rounded-[8px] sm:rounded-tr-[0px] sm:rounded-br-[0px] text-sm sm:text-base"
            value={data.email}
            onChange={(e) => setData("email", e.target.value)}
            // style={{ borderRadius: "8px 0 0 8px" }}
            placeholder="Email Address"
            required
          />
          <Button
            type="submit"
            className="flex sm:inline-block justify-center items-center h-[45px] sm:h-[54px] rounded-[8px] sm:rounded-tl-[0px] sm:rounded-bl-[0px] font-semibold sm:font-normal text-sm sm:text-base"
            // style={{ borderRadius: "0 8px 8px 0", width: "200px" }}
          >
            Join the Community
          </Button>
        </div>
      </form>
    </div>
  );
}
